import React, { useState, useEffect } from 'react';
import '../styles/rewardsModal.css';
import { collectRewards, fetchPXRSBalance, fetchMaps } from '../utils/api';

const RewardsModal = ({
  showRewards,
  rewards,
  setShowRewards,
  setRacingCoins,
  userAccount,
  setNotificationMessage,
  setShowNotification
}) => {
  const [loading, setLoading] = useState(false);
  const [maps, setMaps] = useState({});

  const fetchMapData = async () => {
    try {
      const mapsData = await fetchMaps();
      const mapsDict = mapsData.reduce((acc, map) => {
        acc[map._id] = map;
        return acc;
      }, {});
      setMaps(mapsDict);
    } catch (error) {
      console.error('Error fetching maps:', error.message);
    }
  };

  useEffect(() => {
    fetchMapData();
  }, []);

  const handleCollectRewards = async () => {
    if (loading) return;
    setLoading(true);
  
    try {
      console.log('Starting reward collection...');
  
      if (rewards.length === 0) {
        console.log('No rewards available for collection.');
        setNotificationMessage('No rewards available to collect.');
        setShowNotification(true);
        setLoading(false);
        return;
      }
  
      // Chamando a função collectRewards e assumindo que ela retorna um objeto de dados.
      const totalCoins = await collectRewards(userAccount, rewards);
  
      // Verifique se os dados de recompensa foram retornados corretamente
      if (totalCoins !== undefined && totalCoins !== null) {
        console.log('Rewards collected successfully.');
        setNotificationMessage('Rewards collected successfully!');
      } else {
        // Se não houver recompensa ou dados inválidos
        console.error('Error: Invalid response from reward collection.');
        setNotificationMessage('Error collecting rewards. Please try again.');
      }
  
      setShowNotification(true);
  
      // Atualizando o saldo PXRS após a coleta
      const updatedPxrsBalance = await fetchPXRSBalance(userAccount);
      console.log('PXRS balance updated:', updatedPxrsBalance);
      setRacingCoins(updatedPxrsBalance);
  
      setShowRewards(false);
    } catch (error) {
      console.error('Error collecting rewards:', error);
  
      // Tratamento de erro detalhado
      if (error.response && error.response.data && error.response.data.error) {
        const specificError = error.response.data.error;
  
        if (specificError.includes('minting NFTs')) {
          setNotificationMessage('Error minting NFTs. Please try again later.');
        } else if (specificError.includes('transferring PXRS')) {
          setNotificationMessage('Error transferring PXRS. Please check your balance.');
        } else {
          setNotificationMessage('An unexpected error occurred. Please try again.');
        }
      } else {
        setNotificationMessage('Error collecting rewards. Please try again.');
      }
  
      setShowNotification(true);
    } finally {
      setLoading(false);
      console.log('Reward collection process completed.');
    }
  };

  return (
    <>
      <div className="rewards-overlay" onClick={() => setShowRewards(false)}></div>
      <div className={`rewards-modal ${showRewards ? 'show' : ''}`}>
        <button className="close-button" onClick={() => setShowRewards(false)}>✖</button>
        <div className="rewards-header">
          <h2>REWARDS</h2>
          <button className="collect-all-button" onClick={handleCollectRewards} disabled={loading}>
            {loading ? 'Collecting...' : 'Collect All'}
          </button>
        </div>
        <div className="rewards-list">
          {rewards.map((reward, index) => {
            const mapDetails = maps[reward.mapId] || {};

            return (
              <div key={index} className={`reward-item ${reward.seasonName ? 'ranking-reward' : ''}`}>
                {reward.seasonName ? (
                  <>
                    <div className="reward-ranking-season">{reward.seasonName}</div>
                    <div className="reward-details">
                      <div className="reward-ranking">Ranking: {reward.position}</div>
                      <div className="reward-coins">PXRS: {reward.coins}</div>
                      <div className="reward-ranking-nfts">
                        <div className="reward-ranking-nft-grid">
                          {[...Array(3)].map((_, i) => (
                            reward.nfts[i] ? (
                              <div key={i} className="reward-ranking-nft-slot">
                                <img src={`https://ipfs.io/ipfs/${reward.nfts[i].img}`} alt={reward.nfts[i].name} className="reward-ranking-nft-image" />
                                <div className="reward-ranking-nft-amount">x{reward.nfts[i].amount}</div>
                              </div>
                            ) : (
                              <div key={i} className="reward-ranking-nft-slot empty-ranking-nft-slot"></div>
                            )
                          ))}
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="reward-columns">
                    <div className="reward-column reward-map-column">
                      <img
                        src={mapDetails.image || 'path/to/default/map-image.png'}
                        alt="Map"
                        className="reward-map-image"
                      />
                      <div className="rewardmodal-map-name">{mapDetails.name || 'Unknown Map'}</div>
                    </div>

                    <div className="reward-column reward-details-column">
                      <img
                        src={`https://ipfs.io/ipfs/${reward.carImage}`}
                        alt="Car"
                        className="reward-car-image"
                      />
                      <div className="reward-car-name">{reward.carName}</div>
                      <div className="reward-position">Position: {reward.position}</div>
                      <div className="reward-pxrs">PXRS: {reward.coins}</div>
                    </div>

                    <div className="reward-column reward-nfts-column">
                      <div className="reward-nft-grid">
                        {[...Array(6)].map((_, i) => (
                          reward.nfts[i] ? (
                            <div key={i} className="reward-nft-slot">
                              <img src={`https://ipfs.io/ipfs/${reward.nfts[i].img}`} alt={reward.nfts[i].name} className="reward-nft-image" />
                              <div className="reward-nft-amount">x{reward.nfts[i].amount}</div>
                            </div>
                          ) : (
                            <div key={i} className="reward-nft-slot empty-nft-slot"></div>
                          )
                        ))}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default RewardsModal;
