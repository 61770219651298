import React, { useState, useEffect } from 'react';
import { updateStakeConfig, fetchStakeConfig } from '../../utils/api';
import Cookies from 'js-cookie';

const StakeConfigSettings = () => {
    const [stakeConfig, setStakeConfig] = useState({
      rewardValues: {
        ultimate: 10,
        exotic: 8,
        mythic: 6,
        epic: 4,
        legendary: 3,
        rare: 2,
        uncommon: 1,
        common: 0.5
      },
      totalNftsInStake: {
        ultimate: 0,
        exotic: 0,
        mythic: 0,
        epic: 0,
        legendary: 0,
        rare: 0,
        uncommon: 0,
        common: 0
      },
      saldo: 0,
      status: 'active',
      newBalance: 0
    });
  
    useEffect(() => {
      const loadStakeConfig = async () => {
        const token = Cookies.get('authToken');
        const config = await fetchStakeConfig(token);
        setStakeConfig(config);
      };
      loadStakeConfig();
    }, []);
  
    const handleStakeConfigChange = (event, category, field) => {
      const value = parseFloat(event.target.value);
      setStakeConfig({
        ...stakeConfig,
        [category]: { ...stakeConfig[category], [field]: value }
      });
    };
  
    const handleUpdateStakeConfig = async (updatedConfig = null) => {
      const token = Cookies.get('authToken');
      
      // Filtrando apenas as informações necessárias de stakeConfig, sem incluir referências indesejadas.
      const cleanStakeConfig = {
        rewardValues: stakeConfig.rewardValues,
        saldo: stakeConfig.saldo,
        status: stakeConfig.status,
        newBalance: stakeConfig.newBalance,
        totalNftsInStake: stakeConfig.totalNftsInStake
      };
  
      // Mescla as modificações passadas (se houver) com o estado atual limpo
      const configToUpdate = updatedConfig ? { ...cleanStakeConfig, ...updatedConfig } : cleanStakeConfig;
  
      try {
        await updateStakeConfig(configToUpdate, token);
        alert('Stake configuration updated successfully!');
      } catch (error) {
        console.error('Failed to update stake configuration:', error);
      }
    };
  
    return (
      <div>
        <h3>Stake Configuration</h3>
        <h4>Reward Values</h4>
        {Object.keys(stakeConfig.rewardValues).map((rarity) => (
          <div key={rarity}>
            <label>{rarity}: 
              <input
                type="number"
                value={stakeConfig.rewardValues[rarity]}
                onChange={(e) => handleStakeConfigChange(e, 'rewardValues', rarity)}
              />
            </label>
          </div>
        ))}
  
        <button onClick={handleUpdateStakeConfig}>Update Stake Configuration</button>
  
        <h4>Total NFTs in Stake</h4>
        {Object.keys(stakeConfig.totalNftsInStake).map((rarity) => (
          <div key={rarity}>
            <label>{rarity}: 
              <input
                type="number"
                value={stakeConfig.totalNftsInStake[rarity]}
                disabled
              />
            </label>
          </div>
        ))}
  
        <h4>Stake Balance</h4>
        <div>
          <label>Saldo Atual: {stakeConfig.saldo}</label>
        </div>
        <div>
          <label>Depositar Saldo:</label>
          <input
            type="number"
            value={stakeConfig.newBalance}
            onChange={(e) => setStakeConfig({ ...stakeConfig, newBalance: Number(e.target.value) })}
          />
          <button onClick={() => handleUpdateStakeConfig({ saldo: stakeConfig.saldo + stakeConfig.newBalance })}>
            Depositar
          </button>
        </div>
  
        <div>
          <label>Status Atual: {stakeConfig.status}</label>
          <button onClick={() => handleUpdateStakeConfig({ status: stakeConfig.status === 'active' ? 'inactive' : 'active' })}>
            {stakeConfig.status === 'active' ? 'Desativar' : 'Ativar'} Stake
          </button>
        </div>
      </div>
    );
  };
  
  export default StakeConfigSettings;