// src/components/FooterGame.js

import React from 'react';
import '../styles/footerGame.css';

// Importando os ícones da pasta assets
import IconX from '../assets/x-twitter-icon.png';
import IconDiscord from '../assets/discord-icon.png';
import IconAtomicHub from '../assets/atomic-hub-icon.png';
import IconNeftyBlocks from '../assets/neftyblocks-icon.png';

const FooterGame = () => {
  return (
    <footer className="footer-game">
      <div className="footer-content">
        <div className="footer-icons">
          <a href="https://link-to-x.com" target="_blank" rel="noopener noreferrer">
            <img src={IconX} alt="X Icon" className="footer-icon" />
          </a>
          <a href="https://discord.com" target="_blank" rel="noopener noreferrer">
            <img src={IconDiscord} alt="Discord Icon" className="footer-icon" />
          </a>
          <a href="https://atomic-hub.com" target="_blank" rel="noopener noreferrer">
            <img src={IconAtomicHub} alt="Atomic Hub Icon" className="footer-icon" />
          </a>
          <a href="https://neftyblocks.com" target="_blank" rel="noopener noreferrer">
            <img src={IconNeftyBlocks} alt="NeftyBlocks Icon" className="footer-icon" />
          </a>
        </div>
        <p className="footer-game-text">© 2024 Racing Game. All Rights Reserved.</p>
      </div>
    </footer>
  );
};

export default FooterGame;
