import React, { useEffect, useState } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { fetchFilteredNFTs, checkItemInRace } from '../utils/api';
import '../styles/itemSelection.css';

const ItemSelection = ({ setShowNFTSelector, handleEquipNFT, equippedNFTs, maxItems = 3 }) => {
  const { userAccount } = useAuth();
  const [items, setItems] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [collections, setCollections] = useState([]);
  const [selectedCollection, setSelectedCollection] = useState('');
  const [refreshCount, setRefreshCount] = useState(0);
  const [isCooldown, setIsCooldown] = useState(false);
  const [cooldownTime, setCooldownTime] = useState(0);

  const MAX_REFRESH_CLICKS = 5;
  const COOLDOWN_DURATION = 10; // Duração do cooldown em segundos
  const thirtyDays = 30 * 24 * 60 * 60 * 1000;

  useEffect(() => {
    if (userAccount) {
      loadFilteredItems();
      checkRefreshClicks(); // Verificar cliques de refresh no início
    }
  }, [userAccount, selectedCollection]);

  useEffect(() => {
    let cooldownInterval;

    if (isCooldown && cooldownTime > 0) {
      cooldownInterval = setInterval(() => {
        setCooldownTime(prevTime => prevTime - 1);
      }, 1000);
    } else if (cooldownTime <= 0 && isCooldown) {
      clearInterval(cooldownInterval);
      setIsCooldown(false);
      setCooldownTime(0);
      setRefreshCount(0);
      localStorage.removeItem('refreshClicks_itemSelection'); // Remover contagem de cliques após o cooldown
    }

    return () => clearInterval(cooldownInterval);
  }, [isCooldown, cooldownTime]);

  const checkRefreshClicks = () => {
    const refreshClicks = JSON.parse(localStorage.getItem('refreshClicks_itemSelection')) || [];
    const now = Date.now();
    const validClicks = refreshClicks.filter(clickTime => now - clickTime < 60000); // 1 minuto
    localStorage.setItem('refreshClicks_itemSelection', JSON.stringify(validClicks));
    setRefreshCount(validClicks.length);
  };

  const loadFilteredItems = async () => {
    setIsLoading(true);
    const storageKey = `items_${userAccount}`;
    const cachedData = localStorage.getItem(storageKey);
    const cacheTimestamp = localStorage.getItem(`${storageKey}_timestamp`);

    if (cachedData && cacheTimestamp && (Date.now() - cacheTimestamp < thirtyDays)) {
      const cachedItems = JSON.parse(cachedData);
      const filteredItems = selectedCollection 
        ? cachedItems.filter(item => item.collection.collection_name === selectedCollection) 
        : cachedItems;
      setItems(filteredItems);
      setIsLoading(false);
    } else {
      try {
        const fetchedItems = await fetchFilteredNFTs(userAccount);
        const uniqueCollections = [...new Set(fetchedItems.map(item => item.collection.collection_name))];
        setCollections(uniqueCollections);

        const itemsWithStatus = await Promise.all(fetchedItems.map(async (item) => {
          const isInRace = await checkItemInRace(item.asset_id);
          return { ...item, inRace: isInRace };
        }));

        const finalItems = selectedCollection
          ? itemsWithStatus.filter(item => item.collection.collection_name === selectedCollection)
          : itemsWithStatus;

        setItems(finalItems);

        localStorage.setItem(storageKey, JSON.stringify(finalItems));
        localStorage.setItem(`${storageKey}_timestamp`, Date.now());
      } catch (error) {
        console.error('Error fetching filtered NFTs:', error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleRefresh = () => {
    if (isCooldown) {
      return;
    }

    const now = Date.now();
    const refreshClicks = JSON.parse(localStorage.getItem('refreshClicks_itemSelection')) || [];

    if (refreshCount >= MAX_REFRESH_CLICKS) {
      setIsCooldown(true);
      setCooldownTime(COOLDOWN_DURATION);
      localStorage.setItem('refreshClicks_itemSelection', JSON.stringify(refreshClicks));
    } else {
      localStorage.removeItem(`items_${userAccount}`);
      localStorage.removeItem(`items_${userAccount}_timestamp`);
      loadFilteredItems();

      const updatedClicks = [...refreshClicks, now];
      localStorage.setItem('refreshClicks_itemSelection', JSON.stringify(updatedClicks));
      setRefreshCount(refreshCount + 1);
    }
  };

  const handleSelectItem = (item) => {
    if (item.inRace) {
      return;
    }

    const isAlreadySelected = selectedItems.some(selectedItem => selectedItem.asset_id === item.asset_id);
    const hasSameTemplate = selectedItems.some(selectedItem => selectedItem.template.template_id === item.template.template_id);
    const isAlreadyEquipped = equippedNFTs.some(equippedItem => equippedItem.template.template_id === item.template.template_id);

    if (isAlreadySelected) {
      setSelectedItems(selectedItems.filter(selectedItem => selectedItem.asset_id !== item.asset_id));
    } else if (!hasSameTemplate && !isAlreadyEquipped && selectedItems.length < maxItems) {
      setSelectedItems([...selectedItems, item]);
    }
  };

  const handleEquip = () => {
    selectedItems.forEach(item => {
      handleEquipNFT(item);
    });
    setShowNFTSelector(false);
  };

  const getChanceForItem = (item) => {
    let victoryChance = 0;
    let dropChance = 0;
    let xpBonus = 0;

    if (item.schema?.schema_name === 'items' && item.data?.attributes) {
      const winAttribute = item.data.attributes.find(attr => attr.key === 'winicon-immutable');
      const dropAttribute = item.data.attributes.find(attr => attr.key === 'dropicon-immutable');
      const expAttribute = item.data.attributes.find(attr => attr.key === 'expicon-immutable');

      victoryChance = winAttribute ? parseInt(winAttribute.value) : 0;
      dropChance = dropAttribute ? parseInt(dropAttribute.value) : 0;
      xpBonus = expAttribute ? parseInt(expAttribute.value) : 0;
    } else {
      victoryChance = item.victoryChance || 0;
      dropChance = item.dropChance || 0;
      xpBonus = item.xpBonus || 0;
    }

    return { victoryChance, dropChance, xpBonus };
  };

  return (
    <>
      <div className="item-selection-overlay" onClick={() => setShowNFTSelector(false)}></div>
      <div className="item-selection-modal">
        <div className="item-selection-header">
          <h2>Select Your Item</h2>
          <button className="item-selection-close-button" onClick={() => setShowNFTSelector(false)}>✖</button>
        </div>
        <button 
          className="item-selection-refresh-button" 
          onClick={handleRefresh} 
          disabled={isCooldown}
        >
          {isCooldown ? `Aguarde... ${cooldownTime}s` : 'Refresh'}
        </button>
        <div className="item-selection-filter">
          <label htmlFor="collection-select">Filter by Collection:</label>
          <select
            id="collection-select"
            value={selectedCollection}
            onChange={(e) => setSelectedCollection(e.target.value)}
          >
            <option value="">All Collections</option>
            {collections.map((collection) => (
              <option key={collection} value={collection}>
                {collection}
              </option>
            ))}
          </select>
        </div>
        <div className="item-selection-grid-container">
          {isLoading ? (
            <div className="item-selection-loading-container">
              <p>Loading Items...</p>
            </div>
          ) : (
            <div className="item-selection-grid">
              {items.map((item) => {
                const { victoryChance, dropChance, xpBonus } = getChanceForItem(item); 
                const isSelected = selectedItems.some(selectedItem => selectedItem.asset_id === item.asset_id);
                const isAlreadyEquipped = equippedNFTs.some(equippedItem => equippedItem.template.template_id === item.template.template_id);

                return (
                  <div 
  key={`${item.asset_id}-${item.template.template_id}-${Math.random()}`}  
  className={`item-selection-item ${item.collection.collection_name === 'waxracingwax' ? 'wax-highlight' : ''} ${item.inRace ? 'in-race' : ''} ${item.inStake ? 'in-stake' : ''}`} 
  onClick={() => handleSelectItem(item)}
  style={{ 
    cursor: (isSelected || isAlreadyEquipped || item.inRace || item.inStake) ? 'not-allowed' : 'pointer',
    pointerEvents: item.inRace || item.inStake ? 'none' : 'auto' // Prevent clicks on inactive items
  }}
>
  <div className="item-selection-content">
    {item.inRace && <div className="status-overlay in-race">In Race</div>}
    {item.inStake && <div className="status-overlay in-stake">In Stake</div>}
    <div className="item-selection-image-wrapper">
      <img 
        src={`https://ipfs.io/ipfs/${item.data.img}`} 
        alt={item.name} 
        className="item-selection-image" 
      />
    </div>
    <div className="item-selection-name">
      {item.name}
    </div>
    <div className="item-selection-id">
      {item.asset_id}
    </div>
    <div className="item-chances">
      <p>Win: {victoryChance}%</p>
      <p>Drop: {dropChance}%</p>
      <p>XP: {xpBonus}%</p> 
    </div>
  </div>
</div>

                );
              })}
            </div>
          )}
        </div>

        {selectedItems.length > 0 && (
          <div className="selected-items-container">
            <h3>Selected Items:</h3>
            <div className="selected-items-grid">
              {selectedItems.map((item) => (
                <div 
                  key={item.asset_id} 
                  className="selected-item" 
                  onClick={() => handleSelectItem(item)}
                >
                  <img 
                    src={`https://ipfs.io/ipfs/${item.data.img}`} 
                    alt={item.name} 
                    className="selected-item-image" 
                  />
                  <div className="selected-item-name">
                    {item.name}
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}

        <button className="equip-button" onClick={handleEquip} disabled={selectedItems.length === 0}>
          Equip
        </button>
      </div>
    </>
  );
};

export default ItemSelection;
