import React, { useState } from 'react';
import './About.css';

const About = () => {
  // State to manage which section is open
  const [openSection, setOpenSection] = useState(null);

  const toggleSection = (section) => {
    setOpenSection(openSection === section ? null : section);
  };

  return (
    <div className="about-page">
      <h1>About Pixel Races</h1>
      
      <div className="about-section">
        <h2 onClick={() => toggleSection('howItWorks')}>How the Game Works</h2>
        {openSection === 'howItWorks' && (
          <div className="section-content">
            <p>Pixel Races is a thrilling racing game where you acquire cars and compete in races to earn rewards...</p>
          </div>
        )}
      </div>

      <div className="about-section">
        <h2 onClick={() => toggleSection('cars')}>Cars</h2>
        {openSection === 'cars' && (
          <div className="section-content">
            <p>To participate in races, you need a car from our store on Neftyblocks. Each car has attributes that affect performance:</p>
            <ul>
              <li><strong>Car Rarity:</strong> Common to Ultimate</li>
              <li><strong>Skin Rarity:</strong> Common to Ultimate</li>
              <li><strong>Speed Level:</strong> 1 to 10</li>
              <li><strong>Acceleration Level:</strong> 1 to 10</li>
              <li><strong>Nitro Level:</strong> 1 to 10</li>
              <li><strong>Luck Level:</strong> 1 to 10</li>
            </ul>
            <p>Rarity and levels increase your chances of winning races and dropping NFTs. Select a car to see its attributes and start racing!</p>
          </div>
        )}
      </div>

      <div className="about-section">
        <h2 onClick={() => toggleSection('items')}>Items</h2>
        {openSection === 'items' && (
          <div className="section-content">
            <p>Boost your chances in races by using items from our store or partner collections. Items offer:</p>
            <ul>
              <li><strong>Win Boost:</strong> Higher chances to win or rank higher</li>
              <li><strong>Drop Boost:</strong> Increased NFT drop chances</li>
              <li><strong>EXP Boost:</strong> Gain more EXP to level up faster</li>
            </ul>
            <p>Items have rarities indicating their effectiveness.</p>
          </div>
        )}
      </div>

      <div className="about-section">
        <h2 onClick={() => toggleSection('maps')}>Maps</h2>
        {openSection === 'maps' && (
          <div className="section-content">
            <p>Maps are open to all cars, offering unique challenges with varying race times, number of competitors, and rewards...</p>
          </div>
        )}
      </div>

      <div className="about-section">
        <h2 onClick={() => toggleSection('ranking')}>Ranking</h2>
        {openSection === 'ranking' && (
          <div className="section-content">
            <p>Compete to climb the leaderboard during active seasons by earning EXP and improving your level...</p>
          </div>
        )}
      </div>

      <div className="about-section">
        <h2 onClick={() => toggleSection('ongoingRacesRewards')}>Ongoing Races & Rewards</h2>
        {openSection === 'ongoingRacesRewards' && (
          <div className="section-content">
            <p>Track your active races and collect rewards once they finish. Earn PXRS coins and NFTs...</p>
          </div>
        )}
      </div>

      <div className="about-section">
        <h2 onClick={() => toggleSection('stake')}>Stake</h2>
        {openSection === 'stake' && (
          <div className="section-content">
            <p>Stake your NFTs to earn PXRS coins passively over time. Manage your staked NFTs and earnings here...</p>
          </div>
        )}
      </div>

      <div className="about-section">
        <h2 onClick={() => toggleSection('antiCheating')}>Anti-Cheating System</h2>
        {openSection === 'antiCheating' && (
          <div className="section-content">
            <p>Ensuring fair play, the anti-cheating system prevents the use of the same NFT in multiple races or staking simultaneously...</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default About;
