import React, { useState, useEffect } from 'react';
import { createMap, deleteMap, fetchMaps } from '../../utils/api';
import Cookies from 'js-cookie';
import '../../styles/adminPanel.css';

const MapManagement = () => {
  const [newMap, setNewMap] = useState({
    name: '',
    description: '',
    raceTime: 0,
    totalCoins: 0,
    bots: 0,
    duration: 0,
    image: '',
    nfts: []
  });
  const [maps, setMaps] = useState([]);
  const [nfts, setNfts] = useState([]);
  const [schemas, setSchemas] = useState([]); // Lista de schemas identificados
  const [selectedSchema, setSelectedSchema] = useState(''); // Schema selecionado
  const [loadingTemplates, setLoadingTemplates] = useState(false);
  const [showTemplateSelector, setShowTemplateSelector] = useState(false);

  useEffect(() => {
    const loadMaps = async () => {
      const token = Cookies.get('authToken');
      const fetchedMaps = await fetchMaps(token);
      setMaps(fetchedMaps);
    };
    loadMaps();
  }, []);

  // Fetch NFT templates and schemas
  const fetchTemplates = async () => {
    setLoadingTemplates(true);
    try {
      const response = await fetch(`https://test.wax.api.atomicassets.io/atomicassets/v1/templates?collection_name=waxracingwax`);
      const data = await response.json();

      // Identificar os schemas únicos dos NFTs
      const schemaSet = new Set(data.data.map((nft) => nft.schema.schema_name));
      setSchemas([...schemaSet]); // Armazenar schemas únicos

      setNfts(data.data);
    } catch (error) {
      console.error('Error fetching templates:', error);
    } finally {
      setLoadingTemplates(false);
    }
  };

  const handleMapChange = (event) => {
    const { name, value } = event.target;
    setNewMap({ ...newMap, [name]: value });
  };

  const handleAddMap = async () => {
    const token = Cookies.get('authToken');
    const mapData = {
      ...newMap,
      nfts: newMap.nfts.map((nft) => ({
        template_id: nft.template_id,
        img: nft.img,
        name: nft.name,
        chance: nft.chance,
        max_drop: nft.max_drop,
        max_per_race: nft.max_per_race,
        attributes: nft.attributes
      }))
    };
    await createMap(mapData, token);
    setNewMap({ name: '', description: '', raceTime: 0, totalCoins: 0, bots: 0, duration: 0, image: '', nfts: [] });
    const mapsData = await fetchMaps(token);
    setMaps(mapsData);
  };

  const handleDeleteMap = async (mapId) => {
    const token = Cookies.get('authToken');
    try {
      await deleteMap(mapId, token);
      const mapsData = await fetchMaps(token);
      setMaps(mapsData);
    } catch (error) {
      console.error('Failed to delete map:', error);
    }
  };

  const handleSelectTemplate = (template) => {
    const attributes = Object.entries(template.immutable_data).map(([key, value]) => ({
      trait_type: key,
      value: value,
    }));

    setNewMap({
      ...newMap,
      nfts: [...newMap.nfts, {
        template_id: template.template_id,
        img: template.immutable_data.img,
        name: template.immutable_data.name,
        attributes: attributes,
        chance: 0,
        max_drop: 0,
        max_per_race: 0
      }]
    });

    setShowTemplateSelector(false);
  };

  const handleNftChange = (index, field, value) => {
    const updatedNfts = [...newMap.nfts];
    updatedNfts[index][field] = value;
    setNewMap({ ...newMap, nfts: updatedNfts });
  };

  const handleRemoveNft = (index) => {
    const updatedNfts = [...newMap.nfts];
    updatedNfts.splice(index, 1);
    setNewMap({ ...newMap, nfts: updatedNfts });
  };

  const handleMapImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setNewMap({ ...newMap, image: reader.result });
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSchemaChange = (e) => {
    setSelectedSchema(e.target.value);
  };

  const filteredNfts = nfts.filter((nft) => nft.schema.schema_name === selectedSchema || selectedSchema === '');

  return (
    <div>
      <h3>Map Management</h3>

      <div>
        <label>Map Name:
          <input name="name" value={newMap.name} onChange={handleMapChange} placeholder="Map Name" />
        </label>
      </div>
      <div>
        <label>Map Description:
          <input name="description" value={newMap.description} onChange={handleMapChange} placeholder="Map Description" />
        </label>
      </div>
      <div>
        <label>Race Time (in seconds):
          <input name="raceTime" type="number" value={newMap.raceTime} onChange={handleMapChange} placeholder="Race Time" />
        </label>
      </div>
      <div>
        <label>Total Coins:
          <input name="totalCoins" type="number" value={newMap.totalCoins} onChange={handleMapChange} placeholder="Total Coins" />
        </label>
      </div>
      <div>
        <label>Bots:
          <input name="bots" type="number" value={newMap.bots} onChange={handleMapChange} placeholder="Number of Bots" />
        </label>
      </div>
      <div>
        <label>Duration (in minutes):
          <input name="duration" type="number" value={newMap.duration} onChange={handleMapChange} placeholder="Duration" />
        </label>
      </div>
      <div>
        <label>Map Image:
          <input type="file" name="image" accept="image/*" onChange={handleMapImageChange} />
        </label>
        {newMap.image && <img src={newMap.image} alt="Map Preview" style={{ width: '200px', marginTop: '10px' }} />}
      </div>

      {/* NFT Template Selection */}
      <button onClick={() => setShowTemplateSelector(true)}>Add NFT Template for Map</button>

      {showTemplateSelector && (
        <div className="template-selector-modal">
          <button onClick={() => setShowTemplateSelector(false)}>Close</button>
          <button onClick={fetchTemplates} disabled={loadingTemplates}>
            {loadingTemplates ? 'Loading...' : 'Fetch Templates'}
          </button>

          {/* Filtro de schemas */}
          <div>
            <label>
              Select Schema:
              <select value={selectedSchema} onChange={handleSchemaChange}>
                <option value="">All Schemas</option>
                {schemas.map((schema) => (
                  <option key={schema} value={schema}>{schema}</option>
                ))}
              </select>
            </label>
          </div>

          <div className="nft-grid">
            {filteredNfts.map((nft, index) => (
              <div key={index} onClick={() => handleSelectTemplate(nft)}>
                <img src={`https://ipfs.io/ipfs/${nft.immutable_data.img}`} alt={nft.immutable_data.name} style={{ width: '100px' }} />
                <div>{nft.immutable_data.name}</div>
                <div>Schema: {nft.schema.schema_name}</div>
              </div>
            ))}
          </div>
        </div>
      )}

      {/* Display Selected NFTs and Input Fields for Properties */}
      {newMap.nfts.length > 0 && (
        <div className="selected-nfts">
          {newMap.nfts.map((nft, index) => (
            <div key={index}>
              <p>{nft.name}</p>
              <label>Chance (%): 
                <input 
                  type="number" 
                  value={nft.chance} 
                  onChange={(e) => handleNftChange(index, 'chance', e.target.value)} 
                />
              </label>
              <label>Max Drop: 
                <input 
                  type="number" 
                  value={nft.max_drop} 
                  onChange={(e) => handleNftChange(index, 'max_drop', e.target.value)} 
                />
              </label>
              <label>Max Per Race: 
                <input 
                  type="number" 
                  value={nft.max_per_race} 
                  onChange={(e) => handleNftChange(index, 'max_per_race', e.target.value)} 
                />
              </label>
              <img src={`https://ipfs.io/ipfs/${nft.img}`} alt={nft.name} style={{ width: '100px', marginTop: '10px' }} />
              <p>Attributes:</p>
              <ul>
                {nft.attributes && nft.attributes.length > 0 ? (
                  nft.attributes.map((attr, i) => (
                    <li key={i}>{attr.trait_type}: {attr.value}</li>
                  ))
                ) : (
                  <li>No attributes available.</li>
                )}
              </ul>
              <button onClick={() => handleRemoveNft(index)}>Remover NFT</button>
            </div>
          ))}
        </div>
      )}

      <button onClick={handleAddMap}>Add Map</button>

      <ul>
        {maps.map((map) => (
          <li key={map._id}>
            {map.name}
            <button onClick={() => handleDeleteMap(map._id)}>Delete</button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default MapManagement;
