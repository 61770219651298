import React, { useState } from 'react';
import '../styles/ProfileImageSelector.css';

const ProfileImageSelector = ({ images, onSelectImage, onClose }) => {
  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  const handleSelect = () => {
    if (selectedImage) {
      onSelectImage(selectedImage);
      onClose();
    }
  };

  return (
    <>
      <div className="profile-image-selector-overlay" onClick={onClose}></div>
      <div className="profile-image-selector-modal">
        <div className="profile-image-selector-header">
          <h2>Select Profile Image</h2>
          <button className="profile-image-selector-close-button" onClick={onClose}>✖</button>
        </div>
        <div className="profile-image-selector-grid">
          {images.map((image, index) => (
            <div 
              key={index} 
              className={`profile-image-selector-item ${selectedImage === image ? 'selected' : ''}`}
              onClick={() => handleImageClick(image)}
            >
              <img src={image} alt={`Profile ${index}`} className="profile-image-selector-image" />
            </div>
          ))}
        </div>
        <button className="select-button" onClick={handleSelect} disabled={!selectedImage}>
          Select
        </button>
      </div>
    </>
  );
};

export default ProfileImageSelector;
