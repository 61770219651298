import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { SessionKit } from '@wharfkit/session';
import { WebRenderer } from '@wharfkit/web-renderer';
import { WalletPluginAnchor } from '@wharfkit/wallet-plugin-anchor';
import { WalletPluginCloudWallet } from '@wharfkit/wallet-plugin-cloudwallet';
import { WalletPluginWombat } from '@wharfkit/wallet-plugin-wombat';
import Cookies from 'js-cookie';
import config from '../config';
import logo from '../assets/logo.png'; // Update this path to the correct location of your logo image
import './Header.css';

const Header = () => {
  const { userAccount, login, logout, setRacingCoins, racingCoins } = useAuth();
  const [sessionKit, setSessionKit] = useState(null);
  const [csrfToken, setCsrfToken] = useState('');
  const [menuOpen, setMenuOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const ui = new WebRenderer();
    const anchor = new WalletPluginAnchor();
    const cloudWallet = new WalletPluginCloudWallet();
    const wombat = new WalletPluginWombat();

    const kit = new SessionKit({
      appName: 'Car Racing WAX',
      chains: [{
        id: 'f16b1833c747c43682f4386fca9cbb327929334a762755ebec17f6f23c9b8a12',
        url: 'https://waxtest.api.eosnation.io'
      }],
      ui,
      walletPlugins: [anchor, cloudWallet, wombat],
    });

    setSessionKit(kit);

    const fetchCsrfToken = async () => {
      try {
        const response = await fetch(`${config.apiBaseUrl}/api/auth/csrf-token`, {
          method: 'GET',
          credentials: 'include'
        });
        if (response.ok) {
          const data = await response.json();
          setCsrfToken(data.csrfToken);
          Cookies.set('csrfToken', data.csrfToken, { expires: 1 });
        } else {
          console.error('Failed to fetch CSRF token');
        }
      } catch (error) {
        console.error('Error fetching CSRF token:', error);
      }
    };

    fetchCsrfToken();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      const token = Cookies.get('authToken');
      if (token) {
        const decodedToken = JSON.parse(atob(token.split('.')[1]));
        const expiryTime = decodedToken.exp * 1000;
        const currentTime = Date.now();

        if (expiryTime - currentTime < 10 * 60 * 1000) {
          fetch(`${config.apiBaseUrl}/api/auth/renew-token`, {
            method: 'POST',
            headers: {
              'CSRF-Token': csrfToken || Cookies.get('csrfToken')
            },
            credentials: 'include'
          })
          .then(response => response.json())
          .then(data => {
            Cookies.set('authToken', data.token, { expires: 1 });
          })
          .catch(error => {
            console.error('Error renewing token:', error);
          });
        }
      }
    }, 5 * 60 * 1000);
    return () => clearInterval(interval);
  }, [csrfToken]);

  const handleLogin = async () => {
    if (!sessionKit) return;

    try {
      const loginResult = await sessionKit.login();
      if (loginResult && loginResult.session && loginResult.session.permissionLevel && loginResult.session.permissionLevel.actor) {
        const walletAddress = loginResult.session.permissionLevel.actor;

        Cookies.set('walletAddress', walletAddress);

        const response = await fetch(`${config.apiBaseUrl}/api/auth/login`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'CSRF-Token': csrfToken || Cookies.get('csrfToken')
          },
          body: JSON.stringify({ walletAddress }),
          credentials: 'include'
        });

        const data = await response.json();
        if (response.ok) {
          Cookies.set('authToken', data.token, { expires: 1 });
          setRacingCoins(data.racingCoins);
          checkAdmin(walletAddress, data.token);

          const csrfResponse = await fetch(`${config.apiBaseUrl}/api/auth/csrf-token`, {
            method: 'GET',
            credentials: 'include',
          });

          if (csrfResponse.ok) {
            const csrfData = await csrfResponse.json();
            setCsrfToken(csrfData.csrfToken);
            Cookies.set('csrfToken', csrfData.csrfToken, { expires: 1 });
          }
        } else {
          throw new Error('Failed to log in');
        }
      } else {
        console.error('Login result is invalid:', loginResult);
      }
    } catch (error) {
      console.error('Login failed:', error);
    }
  };

  const checkAdmin = async (walletAddress, token) => {
    try {
      const response = await fetch(`${config.apiBaseUrl}/api/auth/check-admin`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          'CSRF-Token': csrfToken || Cookies.get('csrfToken')
        },
        body: JSON.stringify({ walletAddress }),
        credentials: 'include'
      });

      const data = await response.json();
      if (response.ok) {
        login(walletAddress, data.isAdmin, racingCoins);
      } else {
        login(walletAddress, false, racingCoins);
      }
    } catch (error) {
      console.error('Error checking admin status:', error);
      login(walletAddress, false, racingCoins);
    }
  };

  const handleLogout = () => {
    logout();
    Cookies.remove('authToken');
    Cookies.remove('csrfToken');
    Cookies.remove('walletAddress');
    setCsrfToken('');
  };

  const handlePlayNow = () => {
    navigate('/homegame');
  };

  // Função para renderizar pixels caindo
  const renderFallingPixels = () => {
    const pixels = [];
    const totalPixels = 10; // Aumente o número de pixels
  
    for (let i = 0; i < totalPixels; i++) {
      const style = {
        left: `${(i / totalPixels) * 100}%`, // Distribuição uniforme ao longo do header
        animationDuration: `${2 + Math.random() * 3}s`,
      };
      pixels.push(<div key={i} className="pixel" style={style}></div>);
    }
    return pixels;
  };

  return (
    <header className="menu">
      <div className="logo">
        {/* Use the imported logo image */}
        <img src={logo} alt="Pixel Races Logo" className="logo-image" />
      </div>
      <button className="hamburger" onClick={() => setMenuOpen(!menuOpen)}>
        &#9776;
      </button>
      <nav className={`nav-center ${menuOpen ? 'open' : ''}`}>
        <ul>
          <li><a onClick={() => navigate('/')}>Home</a></li>
          <li><a onClick={() => navigate('/drops')}>Drops</a></li>
          <li><a onClick={() => navigate('/stake')}>Stake</a></li>
          <li><a onClick={() => navigate('/about')}>About</a></li>
          {userAccount && (
            <>
              <li>
                <button className="play-now-button" onClick={handlePlayNow}>
                  <i className="fas fa-gamepad"></i>
                  <span>Play</span>
                </button>
              </li>
              <li>
                <button className="logout-button" onClick={handleLogout}>
                  <i className="fas fa-sign-out-alt"></i>
                  <span>Logout</span>
                </button>
              </li>
            </>
          )}
          {!userAccount && (
            <li>
              <button className="login-button" onClick={handleLogin}>
                <i className="fas fa-sign-in-alt"></i>
                <span>Login</span>
              </button>
            </li>
          )}
        </ul>
      </nav>
      {userAccount && (
        <div className="user-info-container">
          <p className="user-info">{userAccount.toString()}</p>
        </div>
      )}
      {renderFallingPixels()}
    </header>
  );
};

export default Header;